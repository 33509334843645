import React, { useState, useEffect } from 'react';
import {
	Alert,
	InputGroup,
	InputGroupAddon,
	InputGroupText,
	Row,
	Col,
	Button,
} from 'reactstrap';

import { Formik, Field, Form, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import axios from 'axios';

import './signUp.css';

import { useHistory, useLocation } from "react-router-dom";


const API_URL = process.env.REACT_APP_API_URL_BASE;

const sidebarBackground = {
	backgroundRepeat: "no-repeat",
	backgroundPosition: "bottom center"
};

const linkStyle = {
	fontWeight: 600,
	color: "#2bcfe0"
};

const SignUp = () => {

  const history = useHistory();
  const location = useLocation();

  const inviteToken = location.pathname.split("/")[3];

  const [isValidToken, setIsValidToken] = useState(false);
  const [invitationDetails, setInvitationDetails] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  const validateToken = async (token) => {
	try {
	  const response = await axios.post(`${API_URL}validate-signup-invitation/`, { token });
	  if (response.status === 200) {
		let invitation = response.data.details;
		setInvitationDetails(invitation);
		setIsValidToken(true);
	  } else {
		console.log('Invalid token');
	  }
	} catch (error) {
	  console.error('Error validating token', error);
	} finally {
	  setIsLoading(false);
	}
  };  

  useEffect(() => {
    if (inviteToken) {
      validateToken(inviteToken);
    } else {
      setIsLoading(false);
    }
  }, []);

  if (isLoading) {
    return <div></div>;
  }

  if (!isValidToken) {
	history.push('/authentication/login');
  }

  return (
	<div className="auth-wrapper d-flex align-items-center" style={sidebarBackground}>
		<div className="container">
			<div id="signupform">
				<Row className="no-gutters justify-content-center">
					<Col md="12" lg="12">
						<div style={{margin: "0 15% 20px 15%"}}>
							<Alert style={{background: "#b40bbe"}} >
								<span style={{color: "#ffffff"}}>Hey <b>{invitationDetails.first_name} {invitationDetails.last_name}</b> You have been invited to join <a href="https://www.movemusic.io" rel="noopener noreferrer" target="_blank" style={{color: "#ffffff", fontWeight: 'bold'}}><b>Move Music Platform</b></a> Please proceed with signup and confirm your account to unleash the full potential of our services.&nbsp;
									<a href="https://www.movemusic.io" rel="noopener noreferrer" target="_blank" style={{color: "#ffffff", fontWeight: 'bold'}}>
										<u>Learn more about Move Music here</u>
									</a>
								</span>
							</Alert>
						</div>
					</Col>

					<Col md="6" lg="4" className="bg-primary text-white">
						<div className="p-4">
							<h2 className="display-6">Hi,<br /> 
							<span className="text-cyan font-bold">let's send some loud music out there</span></h2>									
						</div>
					</Col>
					<Col md="6" lg="4" className="bg-white">
						<div className="p-4">
							<h3 className="font-medium mb-3">Register Your Account</h3>
							<Formik
								initialValues={{
									username: '',
									first_name: invitationDetails? invitationDetails.first_name: '',
									last_name: invitationDetails? invitationDetails.last_name: '',
									email: invitationDetails? invitationDetails.email: '',
									password: '',
									confirm_password: '',
									acceptTermsOfUsage: false,
									acceptPrivacyPolicy: false,
									acceptCookiePolicy: false,
								}}
								validationSchema={Yup.object().shape({
									username: Yup.string().required('Username is required'),
									first_name: Yup.string().required('First Name is required'),
									last_name: Yup.string().required('Last Name is required'),
									email: Yup
									.string().required('Email is required').email('Email is invalid'),
									password: Yup
									.string()
									.required('Please enter your password')
									.matches(
										/^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/,
										"Password must contain atleast 8 Characters, One Uppercase, One Lowercase, One Number and One Special Case Character"
									),
									confirm_password: Yup.string().oneOf([Yup.ref("password"), null], "Password and Confirm password must match").required('Please confirm your password'),
									acceptTermsOfUsage: Yup.bool().oneOf([true], 'Accepting Terms of Usage is required'),
									acceptPrivacyPolicy: Yup.bool().oneOf([true], 'Accepting Privacy Policy is required'),
									acceptCookiePolicy: Yup.bool().oneOf([true], 'Accepting Cookie Policy is required'),
								})}
								onSubmit={({ username, first_name, last_name, email, password }, { setStatus, setSubmitting }) => {
									setStatus();

									const payload = {
										token: inviteToken,
										username: username,
										first_name: first_name,
										last_name: last_name,
										email: email,
										password: password,
									};

									axios.post(`${API_URL}user-register/`, payload).then(res => {
										if (res.status === 201) {
											history.push('/authentication/signup-successfull');
										}
										else if (res.status === 400)
										{
											let error  = res.data.data.message;
											console.log(error);
											setSubmitting(false);
											setStatus('Something went wrong. Please try again.');
										}
										else {
											setSubmitting(false);
											setStatus('Something went wrong. Please try again.');
										}
									}).catch(function (error) {
										if (error.response) {
											if(error.response.status === 400)
											{
												let error_data = error.response.data;
												let error_msg = error_data.data.message;

												if(error_msg) {
													setSubmitting(false);
													setStatus(error_msg);
												}
												else
												{
													setSubmitting(false);
													setStatus('Something went wrong. Please try again.');
													
												}
											}
											else {
												setSubmitting(false);
												setStatus('Something went wrong. Please try again.');
											}
										} else if (error.request) {
											setSubmitting(false);
											setStatus('Something went wrong. Please try again.');
										} else {
											setSubmitting(false);
											setStatus('Something went wrong. Please try again.');
										}
									});
								}}
								render={({ errors, status, touched, isSubmitting }) => (
									<Form className="mt-3" id="signup-form">
										<InputGroup className="mb-3">
											<InputGroupAddon addonType="prepend">
												<InputGroupText>
													<i className="ti-user"></i>
												</InputGroupText>
											</InputGroupAddon>

											<Field name="username" placeholder="Username" type="text" className={'form-control' + (errors.username && touched.username ? ' is-invalid' : '')} />
											<ErrorMessage name="username" component="div" className="invalid-feedback" />
										</InputGroup>
										<InputGroup className="mb-3">
											<InputGroupAddon addonType="prepend">
												<InputGroupText>
													<i className="ti-user"></i>
												</InputGroupText>
											</InputGroupAddon>

											<Field name="first_name" placeholder="First Name" type="text" className={'form-control' + (errors.first_name && touched.first_name ? ' is-invalid' : '')} />
											<ErrorMessage name="first_name" component="div" className="invalid-feedback" />
										</InputGroup>
										<InputGroup className="mb-3">
											<InputGroupAddon addonType="prepend">
												<InputGroupText>
													<i className="ti-user"></i>
												</InputGroupText>
											</InputGroupAddon>

											<Field name="last_name" placeholder="Last Name" type="text" className={'form-control' + (errors.last_name && touched.last_name ? ' is-invalid' : '')} />
											<ErrorMessage name="last_name" component="div" className="invalid-feedback" />
										</InputGroup>
										<InputGroup className="mb-3">
											<InputGroupAddon addonType="prepend">
												<InputGroupText>
													<i className="ti-email"></i>
												</InputGroupText>
											</InputGroupAddon>

											<Field name="email" placeholder="Email" type="text" className={'form-control' + (errors.email && touched.email ? ' is-invalid' : '')} />
											<ErrorMessage name="email" component="div" className="invalid-feedback" />
										</InputGroup>
										<InputGroup className="mb-3">
											<InputGroupAddon addonType="prepend">
												<InputGroupText>
													<i className="ti-lock"></i>
												</InputGroupText>
											</InputGroupAddon>
											<Field name="password" placeholder="Password" type="password" className={'form-control' + (errors.password && touched.password ? ' is-invalid' : '')} />
											<ErrorMessage name="password" component="div" className="invalid-feedback" />

										</InputGroup>
										<InputGroup className="mb-3">
											<InputGroupAddon addonType="prepend">
												<InputGroupText>
													<i className="ti-lock"></i>
												</InputGroupText>
											</InputGroupAddon>
											<Field name="confirm_password" placeholder="Confirm Password" type="password" className={'form-control' + (errors.confirm_password && touched.confirm_password ? ' is-invalid' : '')} />
											<ErrorMessage name="confirm_password" component="div" className="invalid-feedback" />

										</InputGroup>

										<div className="form-group">
											<div className="form-check form-check-inline">
												<Field type="checkbox" name="acceptTermsOfUsage" className={'form-check-input' + (errors.acceptTermsOfUsage && touched.acceptTermsOfUsage ? ' is-invalid' : '')}  />
												<label className="form-check-label" htmlFor="acceptTermsOfUsage">
													I accept the &nbsp;<a style={linkStyle} href={'https://www.iubenda.com/terms-and-conditions/51840490'} target='_blank' rel='noopener noreferrer'>Terms of Usage</a>
													<ErrorMessage name="acceptTermsOfUsage" component="div" className="invalid-feedback" />
												</label>
											</div>

											<div className="form-check form-check-inline">
												<Field type="checkbox" name="acceptPrivacyPolicy" className={'form-check-input' + (errors.acceptPrivacyPolicy && touched.acceptTermsOfUsage ? ' is-invalid' : '')} />
												<label className="form-check-label" htmlFor="acceptPrivacyPolicy">
													I accept the &nbsp;<a style={linkStyle} href={'https://www.iubenda.com/privacy-policy/51840490/full-legal'} target='_blank' rel='noopener noreferrer'>Privacy Policy</a>
													<ErrorMessage name="acceptPrivacyPolicy" component="div" className="invalid-feedback" />
												</label>
											</div>

											<div className="form-check form-check-inline">
												<Field type="checkbox" name="acceptCookiePolicy" className={'form-check-input' + (errors.acceptCookiePolicy && touched.acceptCookiePolicy ? ' is-invalid' : '')} />
												<label className="form-check-label" htmlFor="acceptCookiePolicy">
													I accept the &nbsp;<a style={linkStyle} href={'https://www.iubenda.com/privacy-policy/51840490/cookie-policy'} target='_blank' rel='noopener noreferrer'>Cookie Policy</a>
													<ErrorMessage name="acceptCookiePolicy" component="div" className="invalid-feedback" />
												</label>
											</div>
										</div>

										<Row className="mb-3">
											<Col xs="12">
												<Button id="signup-submit" size="lg" color="primary" type="submit" block disabled={isSubmitting}>Sign Up</Button>
											</Col>
										</Row>

										{status &&
											<div className={'alert alert-danger new-line'}>{status}</div>
										}

										<div className="justify-content-center d-flex align-items-center">
											Signed Up Already?
											<a
												href="/authentication/login"
												className="forgot text-cyan float-right"
												style={{paddingLeft: 6}}
											>Login</a>
										</div>
									</Form>
								)}
							/>
						</div>
					</Col>
				</Row>
			</div>
		</div>
	</div>
  );
};

export default SignUp;


