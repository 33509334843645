import React, { useState, useEffect } from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, Form, FormGroup, Input, Label } from 'reactstrap';
import axios from 'axios';
import { getCookie } from '../jwt/_helpers';
import LoaderInner from './LoaderInner';

const API_URL = process.env.REACT_APP_API_URL_BASE;
const X_API_KEY = process.env.REACT_APP_X_API_KEY;

const ConsentModal = ({ isConsentModalOpen, setIsConsentModalOpen, currentUser }) => {
    const token = getCookie("token");
    const options = {
      method: "POST",
      mode: 'cors',
      headers: {
        Authorization: `Bearer ${token}`,
        "x-api-key": X_API_KEY,
        "Content-Type": "application/json"
      }
    };
    
    const [consentDocument, setConsentDocument] = useState(null);
    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [email, setEmail] = useState("");  
    const [agreedTerms, setAgreedTerms] = useState(false);
    const [agreedPrivacy, setAgreedPrivacy] = useState(false);
    const [agreedCookies, setAgreedCookies] = useState(false);  

    const [submissionInProgress, setSubmissionInProgress] = useState(false);

    useEffect(() => {
        const fetchLatestConsentDocument = async () => {
        try {
            const response = await axios.get(`${API_URL}consent-documents/`, options);
            let documents = response.data.results;
            if(documents.length>0) {
                setConsentDocument(documents[0]);
            }
        } catch (error) {
            console.error('Error fetching latest consent document:', error);
        }
        };

        if (isConsentModalOpen) {
            fetchLatestConsentDocument();
        }
    }, [isConsentModalOpen]);

    useEffect(() => {
        if(currentUser) {
            setFirstName(currentUser.first_name);
            setLastName(currentUser.last_name);
            setEmail(currentUser.email);
        }
    }, [currentUser]);     


    const handleGiveConsent = async () => {
        setSubmissionInProgress(true);
        window._iub.cons_instructions.push(["submit", {
            form: {
                selector: document.getElementById("consent-form"),
                map: {
                    subject: {
                        first_name: "first_name",
                        last_name: "last_name",
                        email: "email",
                    },
                    exclude: [],
                }
            },
            consent: {
                legal_notices: [
                    {
                        identifier: "privacy_policy",
                        ...consentDocument.privacy_policy_version ? { version: consentDocument.privacy_policy_version } : {},
                    },
                    {
                        identifier: "cookie_policy",
                        ...consentDocument.cookie_policy_version ? { version: consentDocument.cookie_policy_version } : {},
                    },
                    {
                        identifier: "terms",
                        ...consentDocument.terms_of_usage_version ? { version: consentDocument.terms_of_usage_version } : {},
                    }
                ],
            }
        }])
        try {
          const response = await axios.post(`${API_URL}users/record-consent/`, {}, options);
          if (response.status === 200 || response.status === 201) {
            setIsConsentModalOpen(false);
            setSubmissionInProgress(false);
            console.log('Consent recorded successfully');
          } else {
            console.error('Failed to record consent:', response.status, response.data);
            setSubmissionInProgress(false);
          }
        } catch (error) {
          console.error('Error during consent recording:', error);
          setSubmissionInProgress(false);
        }
    };

  return (
    <>
        <LoaderInner show={submissionInProgress} />
        <Modal isOpen={isConsentModalOpen} backdrop="static" keyboard={false}>
            <ModalHeader>Consent Required - {consentDocument?.name} - {consentDocument?.version}</ModalHeader>
            <ModalBody>
            <p>Please review and accept our new consent terms to continue using our services.</p>
            <Form className="mt-3" id="consent-form">
                <FormGroup hidden>
                    <Label>First Name</Label>
                    <Input name="first_name" disabled value={firstName} />
                </FormGroup>
                <FormGroup hidden>
                    <Label>Last Name</Label>
                    <Input  hidden name="last_name" disabled value={lastName} />
                </FormGroup>
                <FormGroup hidden>
                    <Label>Email</Label>
                   <Input hidden name="email" disabled value={email} />
                </FormGroup>

                <FormGroup check>
                <Label check>
                    <Input type="checkbox" className="form-check-input" name="acceptTermsOfUsage" checked={agreedTerms} onChange={() => setAgreedTerms(!agreedTerms)} />{' '}
                    <div className="mt-1">I agree with the {" "} <a href={consentDocument? consentDocument.terms_of_usage_link: ""} target="_blank" rel="noopener noreferrer">Terms Of Usage</a></div>
                </Label>
                </FormGroup>
                <FormGroup check>
                <Label check>
                    <Input type="checkbox" className="form-check-input" name="acceptPrivacyPolicy" checked={agreedPrivacy} onChange={() => setAgreedPrivacy(!agreedPrivacy)} />{' '}
                    <div className="mt-1">I agree with the {" "} <a href={consentDocument? consentDocument.privacy_policy_link: ""} target="_blank" rel="noopener noreferrer">Privacy Policy</a></div>
                </Label>
                </FormGroup>
                <FormGroup check className="form-check-inline">
                <Label check>
                    <Input type="checkbox" className="form-check-input" name="acceptCookiePolicy" style={{marginTop: "3px"}} checked={agreedCookies} onChange={() => setAgreedCookies(!agreedCookies)} />{' '}
                    <div className="mt-1">I agree with the {" "} <a href={consentDocument? consentDocument.cookie_policy_link: ""} target="_blank" rel="noopener noreferrer">Cookie Policy</a></div>
                </Label>
                </FormGroup>
                </Form>
            </ModalBody>
            <ModalFooter>
                <Button color="primary" disabled={!(agreedTerms && agreedPrivacy && agreedCookies) || submissionInProgress} onClick={handleGiveConsent}>Submit</Button>
            </ModalFooter>
        </Modal>
    </>
  );
};

export default ConsentModal;